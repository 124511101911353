import React from 'react';

export default function Footer() {
    return (
        <section className='footer'>
            Copyright {new Date().getFullYear()} 
        </section>

    )
}
